import { Component, OnInit } from "@angular/core";
import { SettingsService } from "../../shared/settings.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export default class LoginComponent implements OnInit {
  login() {
    this.settings.loginWithPopup();
  }
  constructor(private readonly settings: SettingsService) {}

  ngOnInit(): void {}
}
