import { APP_INITIALIZER, NgModule, Version } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
//import { AppRoutingModule } from "./app-routing.module";
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { AuthModule } from "@auth0/auth0-angular";

import { ConfirmationService, MessageService } from "primeng/api";
import { BadgeModule } from "primeng/badge";
import { BlockUIModule } from "primeng/blockui";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { ImageModule } from "primeng/image";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { PanelModule } from "primeng/panel";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RadioButtonModule } from "primeng/radiobutton";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { TreeModule } from "primeng/tree";

import { CandidateDataComponent } from "./components/candidate-data/candidate-data.component";
import { ChangeStatisticsComponent } from "./components/change-statistics/change-statistics.component";
import { KnownVariablesComponent } from "./components/known-variables/known-variables.component";

import { HeaderComponent } from "./components/header/header.component";
import { LoginComponent } from "./components/login/login.component";
import { SalesDataComponent } from "./components/sales-data/sales-data.component";

import { environment } from "../environments/environment";

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ANKAADIA_VERSION, ensure4LetterIsoLanguage, supportedLanguages4LetterISO } from "@ankaadia/ankaadia-shared";
import {
  APP_VERSION,
  FRONTEND_ENVIRONMENT_INJECTION_TOKEN,
  MissingKeyHandler,
  RelaxedTranslocoTranspiler,
  UIBlockInterceptor,
  UILanguageInterceptor,
  VersionInterceptor,
  translocoInitializer,
} from "@ankaadia/ankaadia-shared-frontend";
import {
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER,
  TranslocoModule,
  TranslocoService,
  getBrowserLang,
  provideTransloco,
} from "@jsverse/transloco";
import { TranslocoLocaleModule, provideTranslocoLocale } from "@jsverse/transloco-locale";
import { provideTranslocoPersistLang } from "@jsverse/transloco-persist-lang";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { AuthHttpInterceptorExcel } from "./shared/interceptors/auth-interceptor";
import { TranslocoHttpLoader } from "./shared/translation.loader";

import { UtcFixDirective } from "./shared/directives/utc-fix.directive";
import { OfficeEnvService } from "./shared/officeEnvService";

@NgModule({
  imports: [
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      clientId: environment.auth0_clientId,
      domain: environment.auth0_domain,
      errorPath: "/error",
      skipRedirectCallback: false,
      authorizationParams: {
        audience: environment.auth0_audience,
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [environment.graphqlURI],
      },
    }),
    ButtonModule,
    ImageModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MenuModule,
    ProgressSpinnerModule,
    BlockUIModule,
    PanelModule,
    BadgeModule,
    ConfirmDialogModule,
    CardModule,
    ToastModule,
    MultiSelectModule,
    TabViewModule,
    TreeModule,
    InputTextareaModule,
  ],

  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    ConfirmationService,
    OfficeEnvService,
    { provide: APOLLO_OPTIONS, useFactory: createApollo, deps: [HttpLink] },
    { provide: FRONTEND_ENVIRONMENT_INJECTION_TOKEN, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: UIBlockInterceptor, multi: true }, // Important to be the first
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorExcel, multi: true }, // Auth Interceptor emits two requests, but apparently cancelles one directly
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UILanguageInterceptor, deps: [TranslocoService], multi: true },
    provideTransloco({
      config: {
        availableLangs: supportedLanguages4LetterISO(),
        defaultLang: ensure4LetterIsoLanguage(getBrowserLang()) ?? "de-DE",
        fallbackLang: "de-DE",
        missingHandler: { useFallbackTranslation: environment.production },
        flatten: { aot: false /* aot does not work for us*/ },
        prodMode: environment.production,
        reRenderOnLangChange: false,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale(),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
      storageKey: "language",
    }),
    { provide: TRANSLOCO_TRANSPILER, useClass: RelaxedTranslocoTranspiler },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingKeyHandler },

    { provide: APP_VERSION, useValue: new Version(ANKAADIA_VERSION) },
    MessageService,
  ],
  exports: [TranslocoModule, TranslocoLocaleModule],
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    CandidateDataComponent,
    ChangeStatisticsComponent,
    UtcFixDirective,
    SalesDataComponent,
    KnownVariablesComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const link = <any>httpLink.create({ uri: environment.graphqlURI });
  const error = errorFunc.concat(link);
  return {
    connectToDevTools: true,
    assumeImmutableResults: true,
    link: error,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "none",
  },
};

// GraphQL Error Handling
const errorFunc = onError((errorResponse) => {
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message},Extension: ${extensions["code"]} ,Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});
