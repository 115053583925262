<div class="ml-3 flex flex-column mx-1">
  <h5 class="mt-1">{{ myTranslation("officeAddin.candidateList") }}</h5>
  <div class="flex flex-row gap-3">
    <div class="flex align-items-center">
      <p-radioButton [(ngModel)]="tableMode" value="relational"></p-radioButton>
      <label class="ml-2">{{ myTranslation("officeAddin.relationalMode") }}</label>
    </div>

    <div class="flex align-items-center">
      <p-radioButton [(ngModel)]="tableMode" value="flat"></p-radioButton>
      <label class="ml-2">{{ myTranslation("officeAddin.flatMode") }}</label>
    </div>
  </div>
  <p-dropdown
    [showClear]="true"
    [options]="lists"
    [(ngModel)]="selectedList"
    placeholder="{{ myTranslation('officeAddin.allCandidates') }}"
    optionLabel="name"
    class="mt-3"
  ></p-dropdown>
  <p-button label="{{ myTranslation('officeAddin.importCandidateData') }}" (click)="getData()" class="mt-3"></p-button>
  <ng-template [ngIf]="!this.prodMode">
    <div (click)="clearWorkbook()" class="mt-3 cursor-pointer text-xs">reset workbook (debug)</div>
  </ng-template>
</div>
