import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class OfficeEnvService {
  private bearerToken: string = null;

  getBearerToken(): string {
    return this.bearerToken;
  }

  setBearerToken(newToken: string): void {
    this.bearerToken = newToken;
  }

  getOfficeEnv(): Office.PlatformType {
    return Office.context.platform;
  }

  getHostApplication(): Office.HostType {
    return Office.context.host;
  }
}
