import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  Auth0ClientService,
  AuthClientConfig,
  AuthHttpInterceptor,
  AuthService,
  AuthState,
} from "@auth0/auth0-angular";
import { Auth0Client } from "@auth0/auth0-spa-js";

import { Observable } from "rxjs";
import { OfficeEnvService } from "../officeEnvService";

const noLoginRequiredOperations = ["getLoginLogoUrl", "resolveShortLink"];

@Injectable({ providedIn: "root" })
export class AuthHttpInterceptorExcel extends AuthHttpInterceptor {
  constructor(
    configFactory: AuthClientConfig,
    @Inject(Auth0ClientService) auth0Client: Auth0Client,
    authState: AuthState,
    authService: AuthService,
    private readonly officeEnvService: OfficeEnvService
  ) {
    super(configFactory, auth0Client, authState, authService);
  }

  override intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith("api/graphql") || noLoginRequiredOperations.includes(req.body.operationName)) {
      return next.handle(req);
    } else {
      if (this.officeEnvService.getOfficeEnv() != Office.PlatformType.OfficeOnline) {
        req = req.clone({
          setHeaders: {
            Authorization: `bearer ${this.officeEnvService.getBearerToken()}`,
          },
        });
        return next.handle(req);
      } else {
        return super.intercept(req, next);
      }
    }
  }
}
