import { Component, OnInit } from "@angular/core";
import { CandidateDataService } from "./candidate-data.service";

import { catchError, of } from "rxjs";
import { WriteTablesService } from "../../shared/write-tables.service";
import { ExcelBaseComponent } from "../excel-base/excel-base.component";
import { SettingsService } from "../../shared/settings.service";
import { MessageService } from "primeng/api";
import { TranslocoService } from "@ngneat/transloco";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-candidateData",
  templateUrl: "./candidate-data.component.html",
  styleUrls: ["./candidate-data.component.scss"],
})
export class CandidateDataComponent extends ExcelBaseComponent implements OnInit {
  myDate: Date[] | undefined;
  tableMode: string = "relational";
  lists: any[];
  prodMode = environment.production;

  selectedList: any;

  constructor(
    private readonly candidateDataService: CandidateDataService,
    private readonly writeTables: WriteTablesService,
    private readonly settings: SettingsService,
    override readonly messageService: MessageService,
    override readonly transloco: TranslocoService
  ) {
    super(transloco, messageService);
  }

  ngOnInit(): void {
    this.candidateDataService.getCollectionData().subscribe(
      (data) =>
        (this.lists = data.collection?.map((c) => {
          return { name: c.name, id: c.id, organizationId: c.organizationId };
        }))
    );
  }

  async getData() {
    this.setBusy(true);
    this.candidateDataService
      .getCandidateData(
        this.settings.organizationId,
        this.tableMode,
        this.language,
        this.selectedList?.id,
        this.selectedList?.organizationId
      )
      .pipe(
        catchError((error) => {
          this.sendFailureMessage(error);
          this.setBusy(false);
          return of(null);
        })
      )
      .subscribe(async (d) => {
        if (d) {
          const result = await this.writeTables.writeDataToManyTables(d);
          this.sendTableUpdateMessage(result);
          this.setBusy(false);
        }
      });
  }

  clearWorkbook() {
    this.candidateDataService.deleteAllButFirstTable();
  }
}
