import { Injectable } from "@angular/core";
import { ExcelAddInDto, GetChangeStatisticsDataGQL } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ChangeStatisticsService {
  constructor(private readonly getChangeStatistics: GetChangeStatisticsDataGQL) {}

  getChangeStatisticsData(language: string, fromDate: Date, toDate: Date): Observable<ExcelAddInDto> {
    return this.getChangeStatistics
      .fetch({
        input: {
          language: language,
          fromDate: fromDate,
          toDate: toDate,
        },
      })
      .pipe(map((result: any) => result.data.getChangeStatisticsData));
  }
}
