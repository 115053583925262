import { Injectable } from "@angular/core";
import { supportedUserInterfaceLanguages4LetterISO } from "@ankaadia/ankaadia-shared";
import { Translation, TranslocoLoader } from "@ngneat/transloco";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  private static uiLanguage: string = null;

  async getTranslation(lang: string): Promise<Translation> {
    if (supportedUserInterfaceLanguages4LetterISO().includes(lang)) {
      TranslocoHttpLoader.uiLanguage = TranslocoHttpLoader.uiLanguage ?? lang;
      return import(`../../assets/i18n/${lang}.json`).then((res) => res.default);
    }
    return Promise.all([
      import(`../../assets/i18n/${TranslocoHttpLoader.uiLanguage}.json`).then((res) => res.default),
      import(`../../assets/i18n/${lang}.json`).then((res) => res.default).catch(() => ({})),
    ]).then(([fallback, translation]) => Object.assign({}, fallback, translation));
  }
}
