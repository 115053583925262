import { Injectable } from "@angular/core";
import { CollectionListDto, ExcelAddInDto, GetCandidateDataGQL, GetCollectionListGQL } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateDataService {
  constructor(
    private readonly getCandidateDataQuery: GetCandidateDataGQL,
    private readonly getCollectionListQuery: GetCollectionListGQL
  ) {}

  getCandidateData(
    orgId: string,
    tableMode: string,
    language: string,
    collectionId?: string,
    collectionOwnerId?: string
  ): Observable<ExcelAddInDto> {
    return this.getCandidateDataQuery
      .fetch({
        input: {
          organizationId: orgId,
          tableMode: tableMode,
          language: language,
          collectionId: collectionId,
          collectionOwnerId: collectionOwnerId,
        },
      })
      .pipe(map((result: any) => result.data.getCandidateData));
  }

  getCollectionData(): Observable<CollectionListDto> {
    return this.getCollectionListQuery.fetch().pipe(map((result) => result.data.getCollectionList));
  }

  async deleteAllButFirstTable() {
    await Excel.run(async (context) => {
      const worksheets = context.workbook.worksheets;
      worksheets.load("items");

      await context.sync();

      if (worksheets.items.length > 1) {
        for (let i = 1; i < worksheets.items.length; i++) {
          worksheets.items[i].delete();
          await context.sync();
        }
      }
    });
  }
}
