import { Component, OnInit } from "@angular/core";
// import { TranslocoService } from "@jsverse/transloco";
import { TranslocoService, translate } from "@jsverse/transloco";
import { TreeNode } from "primeng/api";
import { OfficeEnvService } from "../../shared/officeEnvService";
import { KnownVariablesService } from "./known-variables.service";

@Component({
  selector: "app-known-variables",
  templateUrl: "./known-variables.component.html",
  styleUrl: "./known-variables.component.scss",
})
export class KnownVariablesComponent implements OnInit {
  knownVars: TreeNode[];
  selectedVar!: TreeNode[] | TreeNode;
  explanation: string;
  example: string;
  syntax: string;

  treeHeight: string;
  isDesktop = true;

  readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly knownVarsService: KnownVariablesService,
    private readonly officeEnvService: OfficeEnvService,
    protected readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.knownVarsService.getKnownVars().subscribe((data) => (this.knownVars = data));
    this.treeHeight = (document.body.offsetHeight - 600).toString() + "px";
    //relative height properties in CSS do not work, therefore height is set programmatically
    window.addEventListener("resize", () => {
      this.treeHeight = (document.body.offsetHeight - 600).toString() + "px";
    });
    this.isDesktop =
      this.officeEnvService.getOfficeEnv() === Office.PlatformType.PC ||
      this.officeEnvService.getOfficeEnv() === Office.PlatformType.Mac;
  }

  myTranslation(key: string): string {
    return translate(key);
  }

  getData(): void {
    this.knownVarsService.getKnownVars().subscribe((data) => {
      this.knownVars = data;
    });
  }

  nodeSelect(event: any): void {
    this.syntax = event?.node?.data?.syntax ?? "";
    this.explanation = translate(event?.node?.data?.explanation) ?? "";
    this.example = event?.node?.data?.example ?? "";
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.syntax);
  }
}
