<div class="ml-3 flex flex-column mx-1">
  <p-tree
    id="treeComponent"
    [value]="knownVars"
    class="w-full md:w-30rem"
    selectionMode="single"
    [(selection)]="selectedVar"
    (onNodeSelect)="nodeSelect($event)"
    [filter]="true"
    [virtualScroll]="true"
    [virtualScrollItemSize]="30"
    [scrollHeight]="treeHeight"
  ></p-tree>
  <div class="mt-3">
    <label>{{ myTranslation("officeAddin.explanation") }}</label>
    <textarea
      rows="5"
      cols="30"
      id="explanation"
      pInputTextarea
      [(ngModel)]="explanation"
      class="w-full mt-1 h-5rem"
      readonly="true"
    ></textarea>
  </div>
  <div class="mt-3">
    <label>{{ myTranslation("officeAddin.example") }}</label>
    <input pInputText id="example" class="w-full h-2rem mt-1" [(ngModel)]="example" readonly="true" />
  </div>
  <div class="mt-3">
    <span class="p-input-icon-right w-full">
      <i *ngIf="isDesktop" class="pi pi-copy cursor-pointer" (click)="copyToClipboard()"></i>
      <label>{{ myTranslation("officeAddin.syntax") }}</label>
      <label
        *ngIf="!isDesktop"
        [pTooltip]="myTranslation('officeAddin.clipBoardExplanation')"
        class="ml-2 pi pi-info-circle"
      ></label>
      <textarea
        rows="5"
        cols="30"
        id="syntax"
        pInputTextarea
        [(ngModel)]="syntax"
        class="w-full mt-1 h-9rem"
        readonly="true"
      ></textarea>
    </span>
  </div>
</div>
