import { Component, OnInit } from "@angular/core";
import { TranslocoService, translate } from "@ngneat/transloco";
import { ExcelBaseComponent } from "../excel-base/excel-base.component";
import { MessageService, SelectItemGroup } from "primeng/api";
import { SalesDataService } from "./sales-data.service";
import { SalesTableType } from "@ankaadia/graphql";
import { catchError, of } from "rxjs";
import { WriteTablesService } from "../../shared/write-tables.service";

@Component({
  selector: "app-salesData",
  templateUrl: "./sales-data.component.html",
  styleUrls: ["./sales-data.component.scss"],
})
export class SalesDataComponent extends ExcelBaseComponent implements OnInit {
  myDate: Date[] | undefined;
  groupedOrganizations!: SelectItemGroup[];
  selectedOrganizations!: string[];

  dateSelectorState = "";
  organizationSelectorState = "";
  tableSelectorState = "";

  tableTypes: any[] = Object.keys(SalesTableType).map((key) => {
    return { label: translate(`salesTableTypes.${SalesTableType[key]}`), value: key };
  });

  selectedTableType: string[];
  constructor(
    private readonly salesData: SalesDataService,
    override readonly transloco: TranslocoService,
    override readonly messageService: MessageService,
    private readonly writeTablesService: WriteTablesService
  ) {
    super(transloco, messageService);
  }

  ngOnInit(): void {
    this.getOrganizations();
  }

  resetDateSelector() {
    this.dateSelectorState = "";
  }
  resetOrganizationSelector() {
    this.organizationSelectorState = "";
  }
  resetTableSelector() {
    this.tableSelectorState = "";
  }

  async getOrganizations() {
    this.salesData.getOrganizationData().subscribe((d) => {
      if (d) {
        this.groupedOrganizations = [
          {
            label: translate("officeAddin.licensedOrganizations"),
            items: d.organizations
              .filter((x) => x.isLicensed)
              .map((org) => {
                return { label: org.name, value: org.organizationId, isLicensed: org.isLicensed };
              }),
          },
          {
            label: translate("officeAddin.unlicensedOrganizations"),
            items: d.organizations
              .filter((x) => !x.isLicensed)
              .map((org) => {
                return { label: org.name, value: org.organizationId, isLicensed: org.isLicensed };
              }),
          },
        ];
      }
    });
  }

  async getData() {
    let invalid = false;
    if (!this.myDate) {
      this.dateSelectorState = "invalid-entry";
      invalid = true;
    }
    if (!this.selectedTableType || this.selectedTableType.length === 0) {
      this.tableSelectorState = "invalid-entry";
      invalid = true;
    }
    if (!this.selectedOrganizations || this.selectedOrganizations.length === 0) {
      this.organizationSelectorState = "invalid-entry";
      invalid = true;
    }
    if (invalid) return null;
    console.log(this.selectedTableType);
    this.setBusy(true);
    this.salesData
      .getSalesData(
        this.language,
        this.myDate?.[0],
        this.myDate?.[1] ?? this.myDate[0],
        this.selectedTableType.map((type) => type as SalesTableType),
        this.selectedOrganizations
      )
      .pipe(
        catchError((error) => {
          this.sendFailureMessage(error);
          this.setBusy(false);
          return of(null);
        })
      )
      .subscribe(async (d) => {
        if (d) {
          const result = await this.writeTablesService.writeDataToManyTables(d, false);
          this.sendTableUpdateMessage(result);
          this.setBusy(false);
        }
      });
  }
}
