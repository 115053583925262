<div class="ml-3 flex flex-column mx-1">
  <div class="border-bottom-2 mt-3"></div>
  <h5 class="">{{ myTranslation("officeAddin.salesData") }}</h5>
  <span class="p-float-label">
    <p-calendar
      [(ngModel)]="myDate"
      selectionMode="range"
      dateFormat="dd.mm.yy"
      (click)="resetDateSelector()"
    ></p-calendar>
    <label for="float-label" [class]="dateSelectorState">{{ myTranslation("officeAddin.timeFrame") }}</label>
  </span>
  <span class="p-float-label mt-3">
    <p-multiSelect
      [options]="groupedOrganizations"
      [group]="true"
      [(ngModel)]="selectedOrganizations"
      class="mt-3"
      [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"
      (click)="resetOrganizationSelector()"
    >
      ></p-multiSelect
    >
    <label for="float-label" [class]="organizationSelectorState">{{
      myTranslation("officeAddin.organizations")
    }}</label>
  </span>
  <span class="p-float-label mt-3">
    <p-multiSelect
      [options]="tableTypes"
      [(ngModel)]="selectedTableType"
      class="mt-3"
      [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"
      (click)="resetTableSelector()"
    >
    </p-multiSelect>
    <label for="float-label" [class]="tableSelectorState">{{ myTranslation("officeAddin.analysis") }}</label>
  </span>
  <p-button label="Sales Daten importieren" (click)="getData()" class="mt-3"></p-button>
</div>
