import { Injectable } from "@angular/core";
import { GetKnownVarsGQL, KnownVarsNode } from "@ankaadia/graphql";
import { TreeNode } from "primeng/api";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class KnownVariablesService {
  constructor(private readonly getKnownVarsQuery: GetKnownVarsGQL) {}

  getKnownVars(): Observable<TreeNode[]> {
    return this.getKnownVarsQuery.fetch().pipe(
      map((result: any) => {
        // console.log(result.data.getKnownVars.knownVars);
        return this.formatKnownVars(result.data.getKnownVars.knownVars);
      })
    );
  }

  formatKnownVars(knownVars: KnownVarsNode[]): TreeNode[] {
    const rootKnownVars = knownVars.filter((node) => node.parentKey == null);
    console.log(rootKnownVars);

    const knownVarTree = rootKnownVars.map((node) => {
      const childNodes = knownVars.filter((child) => child.parentKey === node.key);
      const treeNode: TreeNode = {
        key: node.key,
        label: node.label,
        data: node.data,
        children: childNodes.map((child) => this.buildChildNodes(child, knownVars)),
      };

      return treeNode;
    });

    return knownVarTree;
  }

  buildChildNodes(node: TreeNode, knownVars: KnownVarsNode[]): TreeNode {
    const childNodes = knownVars.filter((child) => child.parentKey === node.key);
    return {
      key: node.key,
      label: node.label,
      data: node.data,
      children: childNodes.map((child) => this.buildChildNodes(child, knownVars)),
    };
  }
}
