<div class="flex flex-column bg-primary h-8rem">
  <div class="flex flex-row-reverse h-3rem pt-2 pr-2 gap-3">
    <ng-container *ngIf="authenticated">
      <p-confirmDialog></p-confirmDialog>
      <span class="flag flag-{{ flag }} mt-2 cursor-pointer" (click)="menu3.toggle($event)"></span>
      <div class="flex flex-column gap-0 cursor-pointer" (click)="menu.toggle($event)">
        <p class="m-0 p-0">{{ userDisplayName }}</p>
        <p class="text-xs">{{ organizationName }}</p>
      </div>
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <!-- <p-menu #menu2 [model]="orgMenu" [popup]="true"></p-menu> -->

      <p-menu #menu3 [model]="langMenu" [popup]="true"> </p-menu>
      <!-- <div class="text-2xl pi pi-sitemap cursor-pointer mt-2" (click)="menu2.toggle($event)"></div> -->
    </ng-container>
  </div>
  <p-image src="../../../assets/ank_logo_36.png" alt="Ankaadia" class="mt-3 mx-auto"></p-image>
</div>
