<div class="ml-3 flex flex-column mx-1">
  <div class="border-bottom-2 mt-3"></div>
  <h5 class="">{{ myTranslation("officeAddin.changesInTimeFrame") }}</h5>

  <span class="p-float-label">
    <p-calendar
      [(ngModel)]="myDate"
      selectionMode="range"
      dateFormat="dd.mm.yy"
      (click)="resetInvalidDateState()"
    ></p-calendar>
    <label for="float-label" [class]="dateSelectorState">{{ myTranslation("officeAddin.timeFrame") }}</label>
  </span>
  <p-button label="{{ myTranslation('officeAddin.importChanges') }}" (click)="getData()" class="mt-3"></p-button>
</div>
