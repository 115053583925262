import { Injectable } from "@angular/core";
import {
  ExcelAddInDto,
  GetAvailableOrganizationsGQL,
  GetSalesDataGQL,
  OrganizationListDto,
  SalesTableType,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class SalesDataService {
  constructor(
    private readonly getAvailableOrganizationsQuery: GetAvailableOrganizationsGQL,
    private readonly getSalesDataQuery: GetSalesDataGQL
  ) {}

  getOrganizationData(): Observable<OrganizationListDto> {
    return this.getAvailableOrganizationsQuery
      .fetch()
      .pipe(map((result: any) => result.data.getAvailableOrganizations));
  }

  getSalesData(
    language: string,
    fromDate: Date,
    toDate: Date,
    tableTypes: SalesTableType[],
    organizationIds: string[]
  ): Observable<ExcelAddInDto> {
    return this.getSalesDataQuery
      .fetch({
        input: {
          language: language,
          fromDate: fromDate,
          toDate: toDate,
          tables: tableTypes,
          organizationIds: organizationIds,
        },
      })
      .pipe(map((result: any) => result.data.getSalesData));
  }
}
