import { Component, Output, EventEmitter } from "@angular/core";

import { TranslocoService, translate } from "@ngneat/transloco";

import { IresultInfo } from "../../shared/write-tables.service";
import { MessageService } from "primeng/api";

@Component({
  template: "",
})
export class ExcelBaseComponent {
  @Output() isBusy = new EventEmitter<boolean>();

  readonly language = this.transloco.getActiveLang();

  constructor(
    protected readonly transloco: TranslocoService,
    protected readonly messageService: MessageService
  ) {}

  myTranslation(key: string): string {
    return translate(key);
  }

  sendTableUpdateMessage(result: IresultInfo) {
    this.messageService.add({
      severity: result.finishState,
      detail: result.issues
        ? translate("officeAddin.tableWriteIssue") + result.issues
        : translate("officeAddin.tableWriteSuccess") + ` (${result.timeTaken}s.)`,
      life: result.issues ? 5000 : 2000,
    });
  }

  sendFailureMessage(message: string) {
    this.messageService.add({
      severity: "error",
      detail: message,
    });
  }

  setBusy(busyState: boolean) {
    this.isBusy.emit(busyState);
  }
}
